import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService, getHeadersFormUrlEncoded } from '@sbt-suite/components';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import {
    IGruposEditarUsuario,
    IResponseAuthLogin,
    IToken,
    IUsuarioResponseAPI,
    IUsuariosResponseAPI
} from '../models/usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuariosService {
    URL_BASE = environment.URL_BASE;
    MODE = environment.MODE;
    PORT_CORE = environment.PORT_CORE;

    private decodedToken!: IToken;

    constructor(private http: HttpClient, private _cookie: CookieService) {}

    decodeToken() {
        const token = this._cookie.get('access_token');
        if (token) {
            const helper = new JwtHelperService();
            this.decodedToken = helper.decodeToken(token) as IToken;
            return this.decodedToken;
        }
        return false;
    }

    checkIsUserAdminCore() {
        const roles = this.decodedToken?.realm_access?.roles;
        return roles?.some((role: string) => role === 'admin');
    }

    async listarUsuariosPaginados(page: number, per_page: number): Promise<IUsuariosResponseAPI> {
        const params = new HttpParams().set('page', page - 1).set('per_page', per_page);

        return lastValueFrom(
            this.http.get<IUsuariosResponseAPI>(
                buildUrl(this.URL_BASE, `/api/v1/usuarios`, this.PORT_CORE),
                {
                    params,
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    async listarUsuariosSearch(
        page: number,
        per_page: number,
        search: string
    ): Promise<IUsuariosResponseAPI> {
        const params = new HttpParams()
            .set('page', page - 1)
            .set('per_page', per_page)
            .set('search', search);

        return lastValueFrom(
            this.http.get<IUsuariosResponseAPI>(
                buildUrl(this.URL_BASE, `/api/v1/usuarios`, this.PORT_CORE),
                {
                    params,
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    buscarUsuario(id: string): Promise<IUsuarioResponseAPI> {
        return lastValueFrom(
            this.http.get<IUsuarioResponseAPI>(
                buildUrl(this.URL_BASE, `/api/v1/usuario/${id}`, this.PORT_CORE)
            )
        );
    }

    listaGrupos(): Promise<IGruposEditarUsuario[]> {
        return lastValueFrom(
            this.http.get<IGruposEditarUsuario[]>(
                buildUrl(this.URL_BASE, `/api/v1/grupos`, this.PORT_CORE)
            )
        );
    }

    atribuiUsuarioAoGrupo(idUsuario: string, idGrupo: string) {
        return lastValueFrom(
            this.http.put(
                buildUrl(
                    this.URL_BASE,
                    `/api/v1/usuario/${idUsuario}/grupo/${idGrupo}`,
                    this.PORT_CORE
                ),
                {}
            )
        );
    }

    removeUsuarioDoGrupo(idUsuario: string, idGrupo: string) {
        return lastValueFrom(
            this.http.delete(
                buildUrl(
                    this.URL_BASE,
                    `/api/v1/usuario/${idUsuario}/grupo/${idGrupo}`,
                    this.PORT_CORE
                )
            )
        );
    }

    logout(): Promise<any> {
        return lastValueFrom(
            this.http.post(buildUrl(this.URL_BASE, '/api/v1/logout', this.PORT_CORE), {})
        );
    }

    refreshToken(token: string) {
        const params = new HttpParams()
            .set('refresh_token', token)
            .set('grant_type', 'refresh_token')
            .set('aplicacao', 'app-login');

        const URL = this.URL_BASE.replace('/core', '/login');

        return this.http.post<IResponseAuthLogin>(`${URL}/api/v1/login`, params.toString(), {
            headers: getHeadersFormUrlEncoded()
        });
    }

    setTokenUsuario(token: string, refreshToken: string) {
        const currentDate = new Date();
        const expireDate = new Date(currentDate.getTime());
        expireDate.setDate(expireDate.getDate() + 5);

        let domain = '';
        let secure = false;
        let httpOnly = false;

        if (this.MODE === 'dev' || this.MODE === 'prod') {
            domain = 'tvsbt.com.br';
            secure = true;
        }

        this._cookie.set('access_token', token, expireDate, '/', domain, secure, httpOnly);
        this._cookie.set('refreshToken', refreshToken, expireDate, '/', domain, secure, httpOnly);
    }
}
