import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private key = environment.LOCAL_STORAGE_KEY;
    private iv = environment.LOCAL_STORAGE_IV;
    private config: any = {
        mode: CryptoJS.mode.ECB,
        iv: this.iv
    };

    constructor() {}

    // Criptografa os dados antes de armazená-los no LocalStorage
    setItem(key: string, data: any) {
        const encryptedKey = this._encryptKey(key);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
        localStorage.setItem(encryptedKey, encryptedData);
    }

    // Descriptografa os dados ao recuperá-los do LocalStorage
    getItem(key: string): any {
        const encryptedKey = this._encryptKey(key);
        if (encryptedKey) {
            const encryptedData = localStorage.getItem(encryptedKey);
            if (encryptedData) {
                const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.key).toString(
                    CryptoJS.enc.Utf8
                );
                return JSON.parse(decryptedData);
            }
        }
        return null;
    }

    // Remove o item do LocalStorage
    removeItem(key: string) {
        const encryptedKey = this._encryptKey(key);
        localStorage.removeItem(encryptedKey);
    }

    // Limpa todos os itens do LocalStorage
    clear() {
        localStorage.clear();
    }

    private _encryptKey(key: string) {
        return CryptoJS.AES.encrypt(key, CryptoJS.enc.Utf8.parse(this.key), this.config).toString();
    }
}
