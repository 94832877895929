import { AbstractControl, ValidationErrors } from '@angular/forms';
import { IInfinityAutoCompleteResponse } from '@sbt-suite/components';

export function buildUrl(base: string, path: string, port?: string): string {
    return `${base}${port ? `:${port}` : ''}${path}`;
}

export const deserializeDataSelects = (data: IInfinityAutoCompleteResponse) => {
    return {
        total: data.total,
        items: data.items.map((item: any) => ({ label: item.nome, value: item.id }))
    };
};

export function urlValidator(control: AbstractControl): ValidationErrors | null {
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    const value = control.value;
    if (value && !urlPattern.test(value)) {
        return { invalidUrl: true };
    }
    return null;
}
