import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'seguranca/aplicacoes'
    },
    {
        path: 'centrosdecusto',
        loadChildren: () =>
            import('./pages/centro-custo/centro-custo.module').then((m) => m.CentroCustoModule)
    },
    {
        path: 'seguranca/usuarios',
        loadChildren: () =>
            import('./pages/seguranca/seguranca-usuarios/seguranca-usuarios.module').then(
                (m) => m.SegurancaUsuariosModule
            )
    },
    {
        path: 'seguranca/grupos',
        loadChildren: () =>
            import('./pages/seguranca/seguranca-grupos/seguranca-grupos.module').then(
                (m) => m.SegurancaGruposModule
            )
    },
    {
        path: 'seguranca/aplicacoes',
        loadChildren: () =>
            import('./pages/seguranca/seguranca-aplicacoes/seguranca-aplicacoes.module').then(
                (m) => m.SegurancaAplicacoesModule
            )
    },
    {
        path: 'seguranca/workflow',
        loadChildren: () =>
            import('./pages/seguranca/seguranca-workflow/seguranca-workflow.module').then(
                (m) => m.SegurancaWorkflowModule
            )
    },
    {
        path: 'seguranca/userad',
        loadChildren: () =>
            import('./pages/seguranca/seguranca-userad/seguranca-userad.module').then(
                (m) => m.SegurancaUseradModule
            )
    },
    {
        path: 'seguranca/controleacesso',
        loadChildren: () =>
            import(
                './pages/seguranca/seguranca-controle-acesso/seguranca-controle-acesso.module'
            ).then((m) => m.SegurancaControleAcessoModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
